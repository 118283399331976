<template>
  <div class="animation-number-cover">
    <span class="animation-number">{{ $formatNumberWithCommas(displayNumber, ',') }}</span>
  </div>
</template>

<script setup lang="ts">
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const props = withDefaults(
  defineProps<{
    number: number
    fromNumber?: number
    showCoin?: boolean
  }>(),
  {
    number: 0,
    fromNumber: 0,
    showCoin: false
  }
)

const { $formatNumberWithCommas } = useNuxtApp()
const displayNumber = ref<number>(props.fromNumber ? props.fromNumber : 0)
const interval = ref<number | undefined>(undefined)

const watchNumber = () => {
  clearInterval(interval.value)

  if (props.number === displayNumber.value) {
    return
  }
  if (typeof window !== 'undefined') {
    interval.value = window.setInterval(() => {
      if (displayNumber.value !== props.number) {
        let change = (props.number - displayNumber.value) / 10
        change = change >= 0 ? Math.ceil(change) : Math.floor(change)
        displayNumber.value = displayNumber.value + change
      }
    }, 50)
  }
}
watchNumber()

watch(
  () => props.number,
  () => {
    watchNumber()
  }
)
</script>

<style lang="scss" scoped src="assets/scss/components/common/animate-number.scss"></style>
